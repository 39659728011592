<template>
  <button 
    :title="$translate('BOX_HEADING_SHOPPING_CART')"
    @click="openCart"
    :aria-label="ariaLabel"
    class="icon header-shopping-cart-icon" 
    data-slide-in-from-right="data-cart"
  >
    <slot name="icon">
      <component :is="cartIcon" />
    </slot>

    <span v-if="props.showTitle" class="title show-for-large">
      {{ $translate('BOX_HEADING_SHOPPING_CART') }}
    </span>
    <span 
      class="products-count" 
      :class="{ 'has-items' : shoppingCartStore.cartCount > 0 }"
    >
      {{ shoppingCartStore.cartCount }}
    </span>
  </button>
</template>

<script setup>
import { resolveComponent, markRaw, computed, inject } from 'vue';

import CartIconpackage1 from '@/components/icons/CartIcon1.vue';
import CartIconpackage2 from '@/components/icons/CartIcon2.vue';
import CartIconpackage3 from '@/components/icons/CartIcon3.vue';
import CartIconpackage4 from '@/components/icons/CartIcon4.vue';
import CartIconpackage5 from '@/components/icons/CartIcon5.vue';
import CartIconpackage6 from '@/components/icons/CartIcon6.vue';
import { useShoppingCartStore } from '@/stores/shopping-cart.js';
const shoppingCartStore = useShoppingCartStore();
const { $translate } = inject('i18n');

const props = defineProps({
  showTitle: {
    type: Boolean,
    required: false,
    default: false
  }
});

const icons = {
  CartIconpackage1,
  CartIconpackage2,
  CartIconpackage3,
  CartIconpackage4,
  CartIconpackage5,
  CartIconpackage6
}

const cartIcon = computed(() => {
  let name = 'CartIcon' + window.vendre_config.page_header.header_icons;
  const iconComponent = icons[name] ?? resolveComponent(name)
  return markRaw(iconComponent)
});

const ariaLabel = computed(() => {
  return `${$translate('OPEN')} ${$translate('BOX_HEADING_SHOPPING_CART')}`
});

const openCart = () => {
  Accessibility.hideTabIndex('body');
  Accessibility.showVoiceOver('[data-slide-in-object="data-cart"]');
  shoppingCartStore.showCart = true;
}
</script>