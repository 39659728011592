<template>
  <div class="cart-row">
    <!-- Button to remove a product from the cart -->
    <button
      :aria-label="$translate('REMOVE_PRODUCT_FROM_QUOTATION')"
      @click="removeProduct(product.shopping_cart_row_id)"
      class="delete"
    >
      <TrashCanIcon />
    </button>

    <!-- Product image and details -->
    <div class="image">
      <a
        :aria-label="product.product_name"
        :href="product.product.href"
        class="product-overlay"
      >
        <Image
          :alt="product.product.image?.alt || product.product_name"
          :estimated-viewport-widths="{ small: 40, medium: 20, large: 20 }"
          :source="product.product.image?.image"
        />
      </a>
    </div>

    <div class="cart-info">
      <div class="product-info">
        <!-- Product manufacturer and name -->
        <div v-if="product.product.manufacturer_name" class="product-title">
          <a :href="product.product.href" :aria-label="product.product_name">
            {{ product.product.manufacturer_name }}
          </a>
        </div>
        <p>
          {{ product.product_name }}
        </p>

        <!-- Product attributes -->
        <div class="attributes" v-if="product.quotation_product_attributes">
          <div
            v-for="(attribute, idx) in product.quotation_product_attributes"
            :key="idx"
          >
            <span class="name">
              {{ attribute.product_option }}:
            </span>
            <span class="value">
              {{ attribute.product_option_value }}
            </span>
          </div>
        </div>
      </div>

      <!-- Quantity controls for the product -->
      <div class="quantity">
        <button
          :aria-label="$translate('DECREASE_QUANTITY')"
          @click="updateQuantity(product.shopping_cart_row_id, product.quantity - 1)"
          type="button"
        >
          <MinusIcon />
        </button>

        <input
          :aria-label="$translate('PRODUCT_QUANTITY_INPUT')"
          :value="product.quantity"
          @input="updateQuantity(product.shopping_cart_row_id, $event.target.value)"
          class="hide-spin-buttons"
          min="1"
          type="number"
        />

        <button
          :aria-label="$translate('INCREASE_QUANTITY')"
          @click="updateQuantity(product.shopping_cart_row_id, product.quantity + 1)"
          type="button"
        >
          <PlusIcon />
        </button>
      </div>

      <!-- Product price -->
      <div class="price-wrapper">
        <div class="price">
          {{ product.total_price }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import TrashCanIcon from '@/components/icons/TrashCanIcon.vue';
  import MinusIcon from '@/components/icons/MinusIcon.vue';
  import PlusIcon from '@/components/icons/PlusIcon.vue';
  import Image from '@/components/Image.vue';

  const props = defineProps({
    product: {
      type: Object,
      required: true
    }
  });

  const emits = defineEmits(['remove', 'update-quantity']);

  const removeProduct = (productId) => {
    emits('remove', productId);
  };

  const updateQuantity = (productId, quantity) => {
    emits('update-quantity', productId, quantity);
  };
</script>