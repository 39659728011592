<template>
  <div
    class="buy-button-wrapper"
    :data-sticky-buy-mobile="isSticky ? 'true' : 'false' "
  >
    <div v-if="showQuantityInput" class="input-wrapper">
      <ProductQuantityInput 
        v-model="quantity"
        :labelId="productId"
        :lowest-number="props.lowestNumber"
        :package-size="props.packageSize"
        :step="props.packageSize"
      />
      <label :for="productId">
        {{ props.unit }}
      </label>
    </div>

    <div class="button-wrapper">
      <button
        @click="buyProduct"
        type="button"
        class="button primary expanded"
        :class="{ 'full-width': !showQuantityInput }"
        :disabled="disabled"
      >
        {{ buyButtonText }}
      </button>
    </div>
  </div>

  <div v-show="errMsg" class="buy-button-error-wrapper">
    <span role="alert" v-html="errMsg" class="err"></span>
  </div>
</template>

<script setup>
import { ref, computed, inject } from 'vue';
import { useShoppingCartStore } from '@/stores/shopping-cart.js';
import ProductQuantityInput from '@/components/form/ProductQuantityInput.vue';

const shoppingCartStore = useShoppingCartStore();

const { $translate } = inject('i18n');

const props = defineProps({
  productId: {
    required: false,
    type: Number
  },
  showQuantityInput: {
    required: false,
    type: [Boolean, Number],
    default: false
  },
  buttonText: {
    required: false,
    type: String
  },
  unit: {
    type: String,
    required: false,
    default: ''
  },
  disabled: {
    required: false,
    type: Boolean,
    default: false
  },
  lowestNumber: {
    required: false,
    type: Number,
    default: 1
  },
  packageSize: {
    required: false,
    type: Number,
  },
  isSticky: {
    required: false,
    type: Boolean,
    default: false
  },
  hasVariants: {
    required: false,
    type: Boolean,
    default: false
  },
  hasChilds: {
    required: false,
    type: Boolean,
    default: false
  },
});

const productId = ref(props.productId);
const quantity = ref(props.lowestNumber || 1);
const errMsg = ref('');

const buyButtonText = computed(() => {
  let buttonText = props.buttonText || $translate('PRODUCT_BUTTON_BUY');

  if (props.hasVariants && props.hasChilds) { 
    return $translate('CHOOSE_VARIANT');
  } else if (props.disabled) {
    return $translate('OUT_OF_STOCK');
  }

  return buttonText; 
});

const disabled = computed(() => {
  if (props.disabled || props.hasVariants && props.hasChilds) {
    return true;
  } else {
    return false;
  }
});

const buyProduct = () => {
  const product = {
    id: productId.value,
    quantity_diff: quantity.value,
    attributes: {}
  };

  if (document.querySelector('form[name="add_product"]')) {
    const form = document.querySelector('form[name="add_product"]');
    const formData = new FormData(form);

    for (const [key, value] of formData) {
      product.attributes[key] = value;
    }
  }

  shoppingCartStore.setProducts(product)
    .catch(error => {
      errMsg.value = error;

      setTimeout(() => {
        errMsg.value = null;
      }, 7000);
    })
}
</script>