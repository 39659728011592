<template>
  <div class="header-quotation-icon-wrapper">
    <button
      :aria-label="ariaLabel"
      :title="$translate('QUOTATION')"
      @click="openQuotation"
      class="icon"
      data-slide-in-from-right="data-quotation-menu"
    >
      <QuotationIcon />
      <span v-if="showLabel || showCounter" class="title">
        <span v-if="showLabel">
          {{ $translate('QUOTATION') }}
        </span>
        <span v-if="showCounter">
          ({{ amount }})
        </span>
      </span>
      <span v-if="showCounterMobile"
        class="products-count" 
        :class="{ 'has-items' : amount > 0 }"
      >
        {{ amount }}
      </span>
    </button>
  </div>
</template>

<script setup>
  import { ref, watch, computed, inject } from 'vue';
  import QuotationIcon from '@/assets/icons/list.svg?component';
  import { useQuotationStore } from '@/stores/quotation-store.js';

  const quotationStore = useQuotationStore();
  const { $translate } = inject('i18n');

  const props = defineProps({
    showLabel: {
      type: Boolean,
      default: false
    },
    showCounter: {
      type: Boolean,
      default: false
    },
    showCounterMobile: {
      type: Boolean,
      default: false
    }
  });

  const amount = ref(0);

  const ariaLabel = computed(() => {
    return `${$translate('OPEN')} ${$translate('EMAIL_REQUEST_FOR_QUOTATION_SUBJECT')}`
  });

  const openQuotation = () => {
    Accessibility.hideTabIndex('body');
    Accessibility.showVoiceOver('[data-slide-in-object="data-quotation-menu"]');
    Accessibility.showTabIndex('[data-slide-in-object="data-quotation-menu"]');
  }

  watch(() => quotationStore.quotations, (newQuotations) => {
    const draftId = Cookie.getCookie('quotation_draft');
    if (!draftId) {
      amount.value = 0;
      return;
    }
    const rawQuotation = newQuotations[draftId];
    amount.value = rawQuotation?.quotation_products.reduce((total, product) => total + product.quantity, 0) || 0;
  }, { deep: true });

</script>