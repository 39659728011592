<template>
  <div
    class="shop-the-look-wrapper"
    ref="container"
  >
    <AdminEditButton
      :edit-mode="editMode"
      @edit-mode-toggle="editMode = !editMode"
    />
    <p v-if="!image.image" class="text-center">
      {{ $translate('SHOP_THE_LOOK_ADD_IMAGE_TEXT') }}
    </p>
    <img
      :style="[ editMode ? { cursor: 'crosshair' } : '']"
      v-on="editMode ? { click: addPoint } : {}"
      :src="image.image"
      :alt="imageAlt"
      id="shop-the-look-image"
      class="image"
    >
    <Point
      v-for="point in blockJSON.points"
      :key="point.id"
      :edit-mode="editMode"
      :is-active="point.id == activePoint"
      :point="point"
      :container="container"
      @delete-point="deletePoint"
      @dot-click="toggleDot(point.id)"
      @save-point="savePoint"
      @update-coordinates="updateCoordinates"
    />
  </div>
</template>

<script setup>
import { ref, reactive, watch } from 'vue'

const props = defineProps({
  json: {
    required: true,
    type: [String, null]
  },
  image: {
    required: true
  },
  imageAlt: {
    required: false,
    default: '',
    type: String
  }
})

const editMode = ref(false);
const activePoint = ref(null)
const blockJSON = reactive({ points: []})
const container = ref(null)

if (props.json) {
  Object.assign(blockJSON, JSON.parse(props.json));
}

const toggleDot = (pointId) => {
  activePoint.value = activePoint.value == pointId ? null : pointId;
}

const addPoint = (e) => {
  let height = container.value.getBoundingClientRect().height
  let width = container.value.getBoundingClientRect().width

  let x = (e.offsetX / width) * 100;
  let y = (e.offsetY / height) * 100;

  const point = {
    id: General.generateUniqueId(blockJSON.points),
    title: '',
    coordinates: [x, y],
    description: '',
    productData: null,
    color: '#ffffff'
  }

  blockJSON.points.push(point)
  activePoint.value = point.id
}

const savePoint = (pointInput) => {
  blockJSON.points.forEach((point, index) => {
    if (point.id == activePoint.value) {
      blockJSON.points[index] = pointInput.value
    }
  })
  activePoint.value = null
}

const deletePoint = () => {
  blockJSON.points = blockJSON.points.filter((point) => {
    return point.id !== activePoint.value;
  })
}

const updateAdminFields = () => {
  document.querySelector('.shop-the-look-wrapper')
    .closest('[data-edit-block]')
    .querySelector('[data-field="json"]')
    .value = JSON.stringify(blockJSON);
}

const updateCoordinates = (id, coordinates) => {
  blockJSON.points.find(point => point.id == id).coordinates = coordinates;
}

watch(blockJSON, (newValue, oldValue) => {
  updateAdminFields();
})
</script>