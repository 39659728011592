<template>
  <div class="cart-container">
    <div class="header">
      <div class="heading-container">
        <div class="heading">{{ $translate('BOX_HEADING_SHOPPING_CART') }}</div>
        <div role="status" class="sub-heading">
          {{ $translate('YOU_HAVE_X_ITEMS_IN_YOUR_SHOPPING_CART', {
            productCount: shoppingCartStore.cartCount
          }) }}
        </div>
      </div>

      <button 
        @click="closeCart"
        :tabindex="cartTabindex"
        class="close"
        :aria-label="`${$translate('TEXT_CLOSE')} ${$translate('BOX_HEADING_SHOPPING_CART')}`"
        ref="closeCartButton"
        @keydown="handleCheckoutKeydown"
        data-close-cart-button
      >
        <CloseIcon />
      </button> 
    </div>

    <div class="content">
      <ShoppingCartItem
        v-for="product in shoppingCartStore.products"
        :key="`${Date.now()}_${product.id}`"
        :product="product"
        :cartTabindex="cartTabindex"
        :showStockStatus="showStockStatus"
      />
    </div>

    <div class="footer">
      <div v-if="showLeftToFreeShipping" class="shipping-info-container">
        <div>
          <span>Frakt</span>
        </div>
        
        <LeftToFreeShipping class="left-to-free-shipping-component" />
      </div>
      
      <div v-if="shoppingCartStore.products?.length">
        <div class="total-container">
          <span class="text-sum">{{ $translate('BOX_SHOPPING_CART_SUB_TOTAL_BOTTOM') }}</span>
          <span class="total">{{ shoppingCartStore.total }}</span>
        </div>
  
        <div class="buttons-container">
          <a 
            href="/checkout.php"
            class="button primary expanded"
            :aria-label="$translate('BUTTON_SHOPPING_CART')"
            :tabindex="cartTabindex"
            ref="goToCheckoutLink"
            @keydown="handleCheckoutKeydown"
          >
            {{ $translate('BUTTON_SHOPPING_CART') }}
          </a>
          <button
            v-if="quotationActive"
            :aria-label="$translate('ADD_TO_QUOTATION')"
            :tabindex="cartTabindex"
            @click="saveToQuotationDraft"
            @keydown="handleCheckoutKeydown"
            class="button primary hollow expanded"
            ref="createQuotation"
          >
            {{ $translate('ADD_TO_QUOTATION') }}
          </button>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import CloseIcon from '@/components/icons/CloseIcon.vue';
import ShoppingCartItem from '@/components/ShoppingCartItem.vue';
import LeftToFreeShipping from '@/components/LeftToFreeShipping.vue';
import { useShoppingCartStore } from '@/stores/shopping-cart.js';
import { computed, ref } from 'vue';
import { useQuotationStore } from '@/stores/quotation-store.js';

const props = defineProps({
  quotationActive: {
    required: false,
    type: Boolean,
    default: false
  },
  showStockStatus: {
    required: false,
    type: Boolean,
    default: false
  }
});

const showLeftToFreeShipping = window.vendre_config.general.show_left_to_free_shipping;
const closeCartButton = ref();
const goToCheckoutLink = ref();
const createQuotation = ref();

const shoppingCartStore = useShoppingCartStore();
const quotationStore = useQuotationStore();

const saveToQuotationDraft = async (e) => {
  e.preventDefault();

  const draftUuid = Cookie.getCookie('quotation_draft');
  const minifiedProducts = draftUuid ? await quotationStore.getMinifiedProducts(draftUuid) : [];
  
  const newProducts = shoppingCartStore.products.filter(product => 
    !minifiedProducts.some(p => p.id === parseInt(product.id, 10)))
    .map(product => ({
      id: parseInt(product.id, 10),
      quantity: product.quantity,
      attributes: product.attributes ? Object.fromEntries(
        Object.entries(product.attributes).map(([key, attr]) => [key, attr.value_id])
      ) : {}
    })
  );

  minifiedProducts.push(...newProducts);

  const quotation = {
    comment: "Some user comment",
    products: minifiedProducts,
    email_address: "user@example.com"
  };

  if (draftUuid) {
    await quotationStore.editRequestForQuotationDraft(draftUuid, quotation);
  } else {
    await quotationStore.createRequestForQuotationDraft(quotation);
  }

  closeCart();
  document.querySelector('[data-slide-in-from-right="data-quotation-menu"]').click();
};

const cartTabindex = computed(() => {
  return shoppingCartStore.showCart ? 0 : -1;
});

document.addEventListener('exitOffCanvas', () => {
  shoppingCartStore.showCart = false;
});

const handleCheckoutKeydown = (event) => {
  const isTabPressed = event.key === 'Tab';
  const isShiftPressed = event.shiftKey;
  const focusElement = props.quotationActive ? createQuotation : goToCheckoutLink;

  if (isTabPressed) {
    if (!isShiftPressed && event.target === focusElement.value) {
      event.preventDefault();
      closeCartButton.value.focus();
    } else if (isShiftPressed && event.target === closeCartButton.value) {
      event.preventDefault();
      focusElement.value.focus();
    }
  }
};

document.addEventListener('keydown', (event) => {
  if (event.key === 'Escape') {
    closeCart();
  }
});

const closeCart = () => {
  document.querySelector('[data-exit-off-canvas]').click();
  shoppingCartStore.showCart = false;
  Accessibility.hideVoiceOver('[data-slide-in-object="data-cart"]');
}
</script>