<template>
  
  <!-- Toggle popup icon -->
  <button 
    class="products-lists-toggle-wrapper"
    @click.prevent="togglePopup"
    :aria-label="ariaLabel"
  >   
    <HeartIcon 
      :isSolid="customerProductListsStore.isProductInList(productId)" 
      :iconPackage="heartIcon.packageNr"
      :fillColor="heartIcon.fillColor"
      :strokeColor="heartIcon.strokeColor" 
    />
  </button>

  <!-- POPUP -->
  <Teleport to="body">
    <div :id="usePopup" class="product-list-popup-wrapper"
      v-show="showPopup" 
      @click.prevent
    >  
      <div @click.self="togglePopup" class="overlay">
        <div class="product-list-popup">
          <!-- Close -->
          <div class="close-favourite-popup">
            <button 
              @click="togglePopup" 
              aria-hidden="true"
              class="popup-close-icon"
              ref="closePopupButton"
            >
              <CloseIcon />
            </button>
          </div>
  
          <span class="product-list-popup-heading">{{ $translate('P_FAVORITES_ADD_TO_WISHLIST') }}</span>
          <ul class="list">
            <!-- Favorites list-->
            <li @click="toggleProductInFavoritesList">
              <label for="favorites" class="visually-hidden">
                {{ $translate('ADD_TO_CUSTOMERS_PRODUCTS_LIST') }}: {{ $translate('TEXT_MY_FAVORITES') }}
              </label>
              <input
                id="favorites"
                v-model="isFavorite"
                type="checkbox"
              >
              {{ $translate('TEXT_MY_FAVORITES') }}
            </li>
            
            <!-- Custom lists -->
            <li
              v-for="(list, idx) in customerProductListsStore.customLists"
              :key="idx"
              @click="toggleProductInCustomList(productId, list)"
            >
              <label :for="list.name" class="visually-hidden">
                {{ $translate('ADD_TO_CUSTOMERS_PRODUCTS_LIST') }}: {{ list.name }}
              </label>
              <input 
                :value="productId"
                v-model="customerProductListsStore.customListsReducedToProductIds[idx].products"
                type="checkbox"
                :id="list.name"
              >
                {{ list.name }}
            </li>
          </ul>
  
          <div class="row collapse">      
            <div class="small-12 columns create-new-list">
              <label :for="`${Date.now()}-${productId}`" class="visually-hidden">
                {{ $translate('HEADING_TITLE_CREATE_CUSTOMERS_PRODUCTS_LIST') }}
              </label>
              <input
                v-model="newListName" 
                class="add-list-name"
                type="text"
                :id="`${Date.now()}-${productId}`"
              />
            </div>
            <button 
              class="create-list button primary" 
              @click="createNewList"
              :disabled="!newListName"
              :class="{ inactive: !newListName }"
            >
              {{ $translate('HEADING_TITLE_CREATE_CUSTOMERS_PRODUCTS_LIST') }}
            </button>      
          </div>
  
        </div>
      </div>
    </div>
  </Teleport>

</template>

<script>
import { ref, computed, inject } from 'vue';
import { useCustomerProductLists } from '@/stores/customer-products-list.js';
import HeartIcon from '@/components/icons/HeartIcon.vue';
import CloseIcon from '@/assets/icons/close.svg?component';

export default {
  props: {
    productId: {
      required: true,
      type: Number
    }
  },
  components: {
    HeartIcon,
    CloseIcon
  },
  setup(props) {
    const productId = props.productId;
    const showPopup = ref(false);
    const newListName = ref('');
    const closePopupButton = ref(null);
    const { $translate } = inject('i18n');

    const customerProductListsStore = useCustomerProductLists();
    
    const isFavorite = computed(() => {
      return customerProductListsStore.isFavorite(productId);
    });

    const ariaLabel = computed(() => {
      if (window.vendre_config.account.favorites_custom_lists) {
        return showPopup.value ? $translate('CLOSE_CUSTOMERS_PRODUCTS_LISTS_POPUP') : $translate('OPEN_CUSTOMERS_PRODUCTS_LISTS_POPUP');
      } else {
        return isFavorite.value ? $translate('REMOVE_FROM_FAVORITES') : $translate('ADD_TO_FAVORITES');
      }
    });
    
    const usePopup = computed(() => {
      if (window.vendre_config.account.favorites_custom_lists) {
        return `product-list-popup-wrapper-${productId}`;
      } else {
        return;
      }
    })

    function createNewList() {
      customerProductListsStore.createNewList(newListName.value)
        .then(() => {
          newListName.value = '';
        })
        .catch((err) => {
          console.log('Error creating new list');
        });
    }
    
    function togglePopup() {
      newListName.value = '';
      
      // Multiple lists enabled - Open modal
      if (window.vendre_config.account.favorites_custom_lists) {
        showPopup.value = !showPopup.value;

        if (showPopup.value) {
          Accessibility.hideTabIndex('body');
          Accessibility.showTabIndex(`#${usePopup.value}`);
          closePopupButton.value.focus();
        } else {
          Accessibility.showTabIndex('body');
          Accessibility.hideTabIndexForAllHiddenElements();
        }
      }

      // Multiple lists disabled - Toggle product in favorites list
      if (!window.vendre_config.account.favorites_custom_lists) {
        customerProductListsStore.toggleProductInFavoritesList(productId);
      }
    }
    
    function toggleProductInFavoritesList() {
      customerProductListsStore.toggleProductInFavoritesList(productId)
        .catch((err) => {
          console.error('Could not add product to list');
        });
    }

    function toggleProductInCustomList(productId, list) {
      customerProductListsStore.toggleProductInCustomList(productId, list)
        .catch((err) => {
          console.log('Could not toggle product in list');
        });
    }

    const heartIcon = computed(() => {
    let packageNr = window.vendre_config.page_header.header_icons;
    let fillColor = window.vendre_config.general.favorite_icon_solid_color;
    let strokeColor = window.vendre_config.general.favorite_icon_outline_color;
    return { packageNr, fillColor, strokeColor }
    });

    return {
      customerProductListsStore,
      productId,
      showPopup,
      togglePopup,
      toggleProductInFavoritesList,
      isFavorite,
      createNewList,
      newListName,
      toggleProductInCustomList,
      heartIcon,
      usePopup,
      closePopupButton,
      
      // Icons
      HeartIcon,
      CloseIcon,
      ariaLabel
    }
  }
}
</script>
