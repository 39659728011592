<!--
  Twig implementation:

  <div data-vue-wrapper>
    <market-popup
      :markets="{{ boxes.markets.list|json_encode }}"
      :countries="{{ boxes.countries.list|json_encode }}"
      :languages="{{ boxes.languages.list|json_encode }}"
      :ip-country="{{ get_customer_country_by_ip()|json_encode }}"
      :session="{{ session|json_encode }}"
      :checkout="{{ current_script == 'checkout.php' ? 'true' : 'false' }}"
      :logged-in="{{ current_customer_id ? 'true' : 'false' }}"
      :markets-config="{{ config.markets|json_encode }}"
      :svg="{{ {
        'angleRight': include('scss/icons/angle-right.svg')
      }|json_encode }}"
    ></market-popup>
  </div>
-->

<template>
  <aside id="market-popup" class="popup centered market-popup" :class="{ 'active' : active }">
    <div class="popup-inner">
    
      <div class="markets" :class="{ 'slide': activeSlide == 2 }">
        
        <!-- Slide 1 -->
        <h2>{{ $translate('MARKET_POPUP_TITLE') }}</h2>
        <div class="selected-market-wrapper" v-if="selectedMarket">
          <h3 class="selected-market">
            <img v-if="selectedMarket.image" :src="selectedMarket.image.image">
            {{ selectedMarket.name }}
          </h3>

          <ul class="market-info">
            <li v-if="selectedMarket.currency_code">{{ $translate('CURRENCY') }} <span class="currency">{{ selectedMarket.currency_code }}</span></li>
            <li>{{ $translate('LANGUAGE') }} {{ selectedLanguage.name }}</li>
          </ul>
        </div>

        <h3 v-if="selectByCountry && !selectByMarketAndCountry">{{ $translate('MARKET_POPUP_SUGGESTION_TEXT') }}</h3>
        
        <div v-if="!selectByCountry || selectByMarketAndCountry">
          <button
            v-for="market in markets" class="market-link" 
            @click.prevent="setSelectedMarket($event)" 
            :key="market.id" 
            :vkey="market.id" 
            :class="{ 'active': market.id == selectedMarket.id }"
          >
            <img v-if="market.image" :src="market.image.image">
            {{ market.name }}
            <span v-html="svg['angleRight']"></span>
          </button>
        </div>

        <select 
          v-else-if="countryDropdown" 
          @change="setSelectedCountry($event)" 
          v-model="selectedCountryId"
        >
          <option 
            v-for="country in marketCountries" 
            :value="country.id"
          >
            {{ country.name }}
          </option>
        </select>

        <div v-else>
          <span 
            v-for="country in marketCountries" 
            class="country-link" 
            @click="setSelectedCountry($event)" 
            :key="country.id" 
            :vkey="country.id" 
            :class="{ 'active': country.id == selectedCountry.id }"
          >
            {{ country.name }}
          </span>
        </div>

        <!-- Market mismatch -->
        <div class="session-mismatch-wrapper text-center" v-if="showMismatch && storedMarketSelection && sessionMarket">
          {{ $translate('MARKET_POPUP_MISMATCH_TEXT') }}
          <div 
            v-if="storedMarketSelection.marketId != sessionMarket.id" 
            class="session-mismatch-market">{{ sessionMarket.name }}
          </div>
          
          <div 
            v-if="storedMarketSelection.countryId != sessionCountry.id" 
            class="session-mismatch-country"
          >
            {{ sessionCountry.name }}
          </div>
        </div>

        <button 
          v-if="!selectByMarketAndCountry && selectByCountry && countryDropdown" 
          @click="confirmSelection()" 
          class="button primary market-popup-button"
        >
          {{ $translate('MARKET_POPUP_BUTTON_TEXT') }}
        </button>
      </div>

      <!-- Slide 2 - 1. Select market -> 2. Select Country -->
      <div v-if="selectByMarketAndCountry" class="countries" :class="{ 'slide': activeSlide == 2 }">
        
        <div v-if="selectedMarket" class="selected-market-wrapper">
          <h2>{{ $translate('MARKET_POPUP_SELECTED_MARKET') }}</h2>
          <h3 class="selected-market">
            <img 
              v-if="selectedMarket.image" 
              :src="selectedMarket.image.image" 
              :title="selectedMarket.name"
            >
              {{ selectedMarket.name }}
            <button class="change-market" @click.prevent="setActiveSlide(1)">{{ $translate('CHANGE') }}</button>
          </h3>
        </div>

        <select 
          v-if="countryDropdown" 
          @change="setSelectedCountry($event)" 
          v-model="selectedCountryId"
        > 
          <option 
            v-for="country in selectedMarketCountries" 
            :value="country.id"
          >
            {{ country.name }}
          </option>
        </select>

        <div v-else>
          <button 
            v-for="country in selectedMarketCountries" 
            class="country-link" @click.prevent="setSelectedCountry($event)" 
            :key="country.id" 
            :vkey="country.id" 
            :class="{ 'active': country.id == selectedCountry.id }"
          >
            <img :src="country.image" :title="country.name">
            {{ country.name }}
          </button>
        </div>

        <button 
          v-if="countryDropdown" 
          @click="confirmSelection()" 
          class="button primary market-popup-button"
        >
          {{ $translate('MARKET_POPUP_BUTTON_TEXT') }}
        </button>
      </div>

    </div>
  </aside>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  props: {
    markets: {
      required: true,
      type: Array,
    },
    countries: {
      required: true,
      type: Array,
    },
    languages: {
      required: true,
      type: Array,
    },
    session: {
      required: true,
      type: Object,
    },
    ipCountry: {
      required: false,
      type: Object,
    },
    checkout: {
      required: true,
      type: Boolean,
    },
    loggedIn: {
      required: true,
      type: Boolean,
    },
    marketsConfig: {
      required: true,
      type: Object,
    },
    svg: {
      required: false,
      type: Object,
    }
  },
  setup(props) {
    const active = ref(false);
    const activeSlide = ref(1);
    const sessionMarket = ref({});
    const sessionCountry = ref({});
    const sessionLanguage = ref({});
    const selectedMarket = ref({});
    const selectedCountry = ref({});
    const selectedLanguage = ref({});
    const selectedCountryId = ref(0);
    const storedMarketSelection = ref({});
    
    const showMismatch = props.marketsConfig.show_mismatch;
    const selectByMarketAndCountry = props.marketsConfig.select_by_market && props.marketsConfig.select_by_country;
    const countryDropdown = props.marketsConfig.country_dropdown;
    const selectByCountry = props.marketsConfig.select_by_country;
    const selectedMarketCountries = ref([]);
    const marketCountries = ref([]);

    //METHODS
    function init() {
      sessionMarket.value = getMarketbyId(props.session.market_id);
      sessionCountry.value = getCountryById(props.session.country_id);
      sessionLanguage.value = getCountryById(props.session.language_id);
      selectedMarket.value = sessionMarket.value;
      selectedCountry.value = sessionCountry.value;
      selectedLanguage.value = sessionLanguage.value;
      storedMarketSelection.value = JSON.parse(localStorage.getItem('marketSelection'));

      // Build a list of all market countries
      props.markets.forEach(market => {
        if (Object.entries(market.countries).length) {
          Object.entries(market.countries).forEach(([key, value]) => {
            value['id'] = key;
            marketCountries.value.push(value);
          });
        } else {
          marketCountries.value.push(getCountryById(market.standard_country_id));
        }
      });

      // Remove duplicates
      marketCountries.value = _.uniqBy(marketCountries.value, 'id');
      // Sort list
      marketCountries.value.sort(function(a, b) {
        return (a.name > b.name) ? 1 : -1;
      });

      // Pre-select suggested market/country, if use_suggestion_as_default
      // Otherwise, session values are used
      if (props.marketsConfig.use_suggestion_as_default) {
        if (storedMarketSelection.value) {
          var marketFilteredResult = getMarketbyId(storedMarketSelection.value.marketId);
          selectedMarket.value = marketFilteredResult ? marketFilteredResult : selectedMarket.value;
          var countryFilteredResult = getCountryById(storedMarketSelection.value.countryId);
          selectedCountry.value = countryFilteredResult ? countryFilteredResult : selectedCountry.value;
        } else if (props.marketsConfig.suggest_from_ip_lookup && props.ipCountry) {
          var marketFilteredResult = getMarketFromCountryId(props.ipCountry.country_id);
          selectedMarket.value = marketFilteredResult ? marketFilteredResult : selectedMarket.value;
          var countryFilteredResult = getCountryById(props.ipCountry.country_id);
          selectedCountry.value = countryFilteredResult ? countryFilteredResult : selectedCountry.value;
        }
      }

      if (!selectedMarket.value) {
        selectedMarket.value = getMarketFromCountryId(selectedCountry.value.id);
      }

      // selectedCountryId is used primarily in selects
      selectedCountryId.value = selectedCountry.value.id;
      // selectedLanguage exists to support future language selection independent of market
      selectedLanguage.value = selectedMarket.value && selectedMarket.value.language_id ? getLanguageById(selectedMarket.value.language_id) : selectedLanguage.value;
      updateSelectedMarketCountries();

      active.value = showMarketPopupByDefault();

      if (active.value) {
        window.Accessibility.hideTabIndex('body');
        window.Accessibility.showTabIndex('#market-popup');
      }
    }

    function showMarketPopupByDefault() {
      var firstVisit = !storedMarketSelection.value;
      var mismatch = false;
      var noMarket = !sessionMarket;

      if (!firstVisit && sessionMarket.value) {
        var mismatchedMarket = storedMarketSelection.value.marketId !== sessionMarket.value.id;
        var mismatchedCountry = selectByCountry && storedMarketSelection.value.countryId !== sessionCountry.value.id;
        var mismatch = mismatchedMarket || mismatchedCountry;
      }

      var showMarketPopup = firstVisit || mismatch || noMarket;
      if (props.loggedIn || window.location.href.indexOf("NOMARKETPOPUP") > -1 || window.location.href.indexOf("market_id") > -1) {
        showMarketPopup = false;
      }

      return showMarketPopup;
    }

    function updateSelectedMarketCountries() {
      selectedMarketCountries.value = [];

      if (typeof selectedMarket.value === 'object') {
        if (Object.entries(selectedMarket.value.countries).length) {
          Object.entries(selectedMarket.value.countries).forEach(([key, value]) => {
            value['id'] = key;
            selectedMarketCountries.value.push(value);
          });
        } else {
          selectedMarketCountries.value.push(getCountryById(selectedMarket.value.standard_country_id));
        }
      } else {
        selectedMarketCountries.value = marketCountries.value;
      }
    
    }

    function itemIdInSelection(itemId, selection) {
      return selection.filter(selectionItem => selectionItem.id == itemId)[0] ? true : false;
    }
    
    function getMarketbyId(marketId) {
      let market = props.markets.filter(market => market.id == marketId)[0];
      return market;
    }

    function getCountryById(countryId) {
      return props.countries.filter(country => country.id == countryId)[0];
    }
    
    function getLanguageById(languageId) {
      let lang = props.languages.filter(language => language.id == languageId)[0];
      return lang;
    }
    
    function getMarketFromCountryId(countryId) {
      return props.markets.filter(market => market.countries[countryId] || market.standard_country_id == countryId)[0];
    }

    function setSelectedMarket(event) {
      selectedMarket.value = getMarketbyId(event.target.getAttribute('vkey'));
      
      if (selectedMarket.value.language_id) {
        selectedLanguage.value = getLanguageById(selectedMarket.value.language_id)
      }
      
      if (selectByMarketAndCountry) {
        setActiveSlide(2);
        updateSelectedMarketCountries();
      }
      
      if (!selectByMarketAndCountry || !itemIdInSelection(selectedCountryId.value, selectedMarketCountries.value)) {
        selectedCountryId.value = selectedMarket.value.standard_country_id;
        selectedCountry.value = getCountryById(selectedCountryId.value);
      }
      
      if (!selectByMarketAndCountry) {
        confirmSelection();
      }
    }

    function setSelectedCountry() {
      selectedCountryId.value = event.target.getAttribute('vkey') ? event.target.getAttribute('vkey') : selectedCountryId.value;
      selectedCountry.value = getCountryById(selectedCountryId.value);

      if (!selectByMarketAndCountry) {
        selectedMarket.value = getMarketFromCountryId(selectedCountryId.value);
        selectedLanguage.value = selectedMarket.value.language_id ? getLanguageById(selectedMarket.value.language_id) : selectedLanguage.value;
      }

      if (!countryDropdown) {
        confirmSelection();
      }
    }

    function setActiveSlide(newSlide) {
      activeSlide.value = newSlide;
    }
    
    function redirectAfterSelection(redirectUrl) {
      if (props.checkout) {
        window.location.reload();
      } else {
        window.location.assign(redirectUrl);
      }
    }

    function confirmSelection() {
      var redirectUrl = '';
      localStorage.setItem('marketSelection', JSON.stringify({marketId: selectedMarket.value.id, countryId: selectedCountry.value.id}));

      if (selectedMarket.value.domain_name_href.includes('market_id')) {
        redirectUrl = selectedMarket.value.url_prefix.length ? '/' + selectedMarket.value.url_prefix + '/' : '/';
      } else {
        redirectUrl = selectedMarket.value.domain_name_href;
      }

      if (
        selectedCountry.value.id != selectedMarket.value.standard_country_id ||
        selectedLanguage.value.id != selectedMarket.value.language_id
      ) {
        var data = {
          'hide_ajax_loader': 'true',
          'market_id': selectedMarket.value.id,
          'country_id': selectedCountry.value.id
        };

        if (selectedLanguage.value.id) {
          data['language'] = selectedLanguage.value.id;
        }

        var that = this;
        $.get('/ajax.php', data)
          .always(function () {
            that.redirectAfterSelection(redirectUrl);
          });
      } else {
        redirectAfterSelection(redirectUrl);
      }
    }

    onMounted(() => {
      init();
    })
    
    

    return {
      active,
      activeSlide,
      sessionMarket,
      sessionCountry,
      sessionLanguage,
      selectedMarket,
      selectedCountry,
      selectedLanguage,
      selectedCountryId,
      storedMarketSelection,
      selectByMarketAndCountry,
      selectedMarketCountries,
      countryDropdown,
      selectByCountry,
      marketCountries,
      showMismatch,

      init,
      confirmSelection,
      showMarketPopupByDefault,
      updateSelectedMarketCountries,
      itemIdInSelection,
      getMarketbyId,
      getCountryById,
      getLanguageById,
      getMarketFromCountryId,
      setSelectedMarket,
      setSelectedCountry,
      setActiveSlide,
      redirectAfterSelection
    }
  }
}

  

</script>