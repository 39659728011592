<template>
  <div v-show="levelStates.length" class="chained-filters-wrapper">
    <div class="chained-filters-information">
      <h2 v-if="header">
        {{ header }}
      </h2>
      
      <p v-if="description">
        {{ description }}
      </p>
    </div>

    <div class="selectors-container">
      <div
        v-for="(level, idx) in levelStates"
        class="select-container"
        :key="idx"
      >
        <label :for="level.name">
          {{ level.name }}
        </label>
        <select
          :ref="`filter${idx}`"
          :disabled="levelStates[idx - 1] && !levelStates[idx - 1].selectedValue ? true : false"
          v-model="levelStates[idx].selectedValue"
          @change="handleChange(idx, $event)"
        >
          <option selected disabled>
            {{ level.name }}
          </option>

          <option
            v-if="idx == 0 || levelStates[idx - 1].selectedValue"
            v-for="option in getLevelValues(idx)"
            :key="option.name"
            :data-filter-value="option.value"
          >
            {{ option.name }}
          </option>
        </select>
      </div>
    </div>

    <div v-show="disableClearButton">
      <button 
        class="clear-btn button" 
        @click="clearAllFilters"
      >
        Rensa filter
      </button>
    </div>
  </div>
</template>
  
<script>
/** Implementation notes at bottom of script tag */
import { ref, computed } from 'vue';

export default {
  props: {
    filters: Array,
    settings: Object,
  },
  setup(props) {
    const filter_settings = props.settings.filters;
    let filter = null;
    const levelStates = ref([]);
    
    if (filter_settings) {
      //Check if there are any chained filters
      for (let i = 0; i < props.filters.length; i++) {
        
        //Check if filter exists in store settings
        for (let z = 0; z < filter_settings.length; z++) {
          if (props.filters[i].id == filter_settings[z].filter_id) {
            
            //Chained filter found - Set as filter that the component works with
            filter = props.filters[i];
            filter.settings = filter_settings[z];
            break;
          }
        }
      }
  
      //Set up level states
      if (filter?.settings) {
        for (let i = 0; i < filter.settings.levels.length; i++) {
          let newLevel = {
            name: filter.settings.levels[i],
            selectedValue: null
          }
          levelStates.value.push(newLevel);
        }
      }
    }
    
    function getLevelValues(level) {

      //Options to return
      let levelOptions = [];

      //Filter options based on parent
      let options = filter.options;
      for (let i = 0; i < options.length; i++) {
        let newOption = {};
        
        /** Chained filters are stored like this example: "Manufacturer > VehicleType > Model"
         * String is split so we can match with parent choices in the chain. * */ 
        let optionLevels = options[i].name.split('>');

        //Remove whitespaces
        optionLevels = optionLevels.map(option => option.trim());

        //Check if previous filters match
        let matchedWithParentFilters = true;

        //If level is 0, add all options
        if (level > 0) {

          //Don't add "Universell" since those updates are handled on all form changes except on last level.
          if (optionLevels[level] == 'Universell') {
            continue;
          }

          for (let x = 0; x < level; x++) {
            if (levelStates.value[x].selectedValue !== optionLevels[x]) {
              matchedWithParentFilters = false;
            }
          }
        }

        //Option doesn't match parent choices, skip to next
        if (matchedWithParentFilters == false) {
          continue;
        }

        //Option matches parent filters - Create new option
        newOption.name = optionLevels[level];
        newOption.value = options[i].settings.value;
        
        //Check if option already exists in array
        let isUnique = checkForDuplicate(newOption, levelOptions);

        //Duplicate exists, don't add
        if (!isUnique) {
          continue;
        }

        //Everything ok, add option
        levelOptions.push(newOption);
      }

      return levelOptions;
    }

    
    function checkForDuplicate(item, array) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].name == item.name) {
          return false;
        }
      }
      return true;
    }
    
    function clearChildFilters(index) {
      let i = index + 1;
      for (i; i < levelStates.value.length - 1; i++) {
        levelStates.value[i].selectedValue = null;
      }
    }
    
    function clearAllFilters() {
      levelStates.value.forEach(item => item.selectedValue = null);
      
      General.dispatchGlobalEvent('vendreVueFilterUpdate', { 
        action: 'clearAllFilters',
        data:  null,
      });
    }
    
    //Form change + final filter logic
    function handleChange(index, event) {
      let chainedFilterSearchParams = new URLSearchParams();
      let filterBaseUrl = `filter[${filter.id}]`;

      //Build filter URLs
      /* 
        This adds "Universell" filters on all levels except last one like this.
        Volvo > Universell, 
        Volvo > Grävmaskin > Universell
      */
      for (let i = 0; i <= index; i++) {
        let levelUrl = '';
        for (let z = 0; z <= i; z++) {
          levelUrl += `${levelStates.value[z].selectedValue}`;
          if (z !== levelStates.value.length - 1) {
            levelUrl += ' > ';
          }
        }
        //Add this if not last level of filter
        if (i < levelStates.value.length - 1) {
          levelUrl += `Universell`;
        }
        chainedFilterSearchParams.append(`${filterBaseUrl}[${levelUrl}]`, levelUrl);
      }
      
      /** Transfer searchParamsObject to filter.js that handles updating the category page */
      General.dispatchGlobalEvent('vendreVueFilterUpdate', {
        action: 'filterProducts',
        data: chainedFilterSearchParams
      });

      clearChildFilters(index);
    }
  
    const disableClearButton = computed(() => {
      if (levelStates.value[0]?.selectedValue) {
        return true;
      }
      return false;
    });

    return {
      levelStates,
      getLevelValues,
      checkForDuplicate,
      clearChildFilters,
      clearAllFilters,
      handleChange,
      disableClearButton
    }
  }
}

/**
To activate a filter on a category, you have to specify filter_id + levels in
Custom store settings in "Admin -> Vendre -> Butiksunika inställningar"
You can add as many categories as you wish.

  Store settings:
  {
    "filters": [
      {
        "filter_id": 304, 
        "levels": ["Märke", "Fordonstyp", "Modell"]
      }
    ]
  }

  Implementation on categories page:
  {% set chained_filter_settings = get_store_setting("CHAINED_FILTERS")|json_encode %}
  <div data-vue-wrapper>
    <chained-filters
      :filters="{{ filters|json_encode }}"
      :settings="{{ chained_filter_settings|json_decode }}"
    ></chained-filters>
  </div>

  Written by:
  Andreas Johansson
*/
</script>
  
<style lang="scss" scoped>

</style>