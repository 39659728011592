<template>
  <div 
    :class="filterPosition"
    class="listing-sort-widget"
  >
    <span role="status" class="number-of-found-products">
      {{ productCount }} {{ productCountText }}
    </span>
    
    <div v-if="sortOptions" class="sort-wrapper" data-sort-menu>
      {{ $translate('SORT_BY') }}:
      <button 
        @click="toggleSortDropdown" 
        :class="{ open : showSortDropdown }"
      >
        <span>
          <span>{{ sortByText }} </span>
          {{ sortByValue }}
        </span>
        <ChevronDownIcon />
      </button>

      <div 
        v-show="showSortDropdown"
        class="sort-dropdown"
      >
        <div
          v-for="(option, idx) in sortOptions"
          class="input-wrapper"
        >
          <button
            :class="{'selected-option': option == selectedSortOrder}"
            @click="handleSelectSortOrder(option)"
          >
            {{ option.label }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useListingSortWidget} from '@/composables/listing-sort-widget.js';

const props = defineProps({
  sortOrder: String,
  sortBy: String,
  sortOptionsTwig: Object,
  sortOptionsTweakwise: Array,
  showProductCount: {
    type: Boolean,
    required: false
  },
  productCount: {
    type: Number,
    required: true
  },
  filterPosition : {
    type: String,
    required: false
  }
});

const handleSelectSortOrder = (option) => {
  selectedSortOrder.value = option;
}

const {
  productCount,
  productCountText,
  sortOptions,
  toggleSortDropdown,
  showSortDropdown,
  sortByText,
  sortByValue,
  selectedSortOrder
} = useListingSortWidget(props);
</script>