<template>
  <div class="quotation cart-container">
    <div class="header">
      <div class="heading-container">
        <div class="heading">

          <!-- Display different headings based on whether the quotation is sent or not -->
          <span v-if="!quotationSent">
            {{ $translate('EMAIL_REQUEST_FOR_QUOTATION_SUBJECT') }}
          </span>
          <span v-else>
            {{ $translate('THANK_YOU') }}
          </span>
        </div>
      </div>

      <!-- Close button for the cart -->
      <button
        :aria-label="`${$translate('TEXT_CLOSE')} ${$translate('EMAIL_REQUEST_FOR_QUOTATION_SUBJECT')}`"
        @click="closeCart"
        @keydown="handleCheckoutKeydown"
        class="close"
        ref="closeCartButton"
        data-close-quotation-cart
      >
        <CloseIcon />
      </button>
    </div>

    <!-- Customer form for entering details, shown if the form is enabled and the quotation is not sent -->
    <div v-if="customerForm && !quotationSent" class="quotation-form">
      <div v-if="quotationProducts.length" class="customer-info">

        <!-- Input fields for customer details -->
        <label for="customer-company">
          {{ $translate('COMPANY') }}
          <input
            v-model="customerCompany"
            :placeholder="$translate('COMPANY')"
            type="text"
          />
        </label>

        <div class="customer-name">
          <label for="customer-name">
            {{ $translate('FIRST_NAME_PLACEHOLDER') }}
            <input
              v-model="customerFirstName"
              :placeholder="$translate('FIRST_NAME_PLACEHOLDER')"
              type="text"
            />
          </label>
          <label for="customer-name">
            {{ $translate('LAST_NAME_PLACEHOLDER') }}
            <input
              v-model="customerLastName"
              :placeholder="$translate('LAST_NAME_PLACEHOLDER')"
              type="text"
            />
          </label>          
        </div>

        <label for="customer-email">
          {{ $translate('LOGINBOX_EMAIL') }} <span class="required">*</span>
          <input
            v-model="customerEmail"
            :placeholder="$translate('LOGINBOX_EMAIL')"
            type="email"
          />
        </label>

        <!-- Display email error if validation fails -->
        <div v-if="emailError" class="email-error" role="alert">
          {{ $translate('QUOTATION_EMAIL_ERROR') }}
        </div>

        <label for="customer-phone">
          {{ $translate('ENTRY_TELEPHONE_NUMBER') }}
          <input
            v-model="customerMobile"
            :placeholder="$translate('ENTRY_TELEPHONE_NUMBER')"
            type="tel"
          />
        </label>

        <!-- Textarea for customer comments -->
        <div class="customer-comment">
          <label for="customer-comment">
            {{ $translate('COMMENT') }}
            <textarea
              v-model="customerComment"
              :placeholder="$translate('COMMENT')"
              rows="3"
            >
            </textarea>
          </label>
        </div>

        <!-- Checkbox for approving personal data -->
        <div class="approve-personal-data">
          <input
            v-model="personalDataApproved"
            id="approve-personal-data"
            type="checkbox"
          />
          <label for="approve-personal-data">
            {{ approvePersonalDataText }} <span class="required">*</span>
          </label>
        </div>
      </div>

      <!-- Button to send the quotation request -->
      <div v-if="quotationProducts.length" class="send-quotation text-center">
        <button
          :aria-label="$translate('SEND_REQUEST')"
          :class="{ disabled: !customerEmail || !personalDataApproved }"
          @click="sendQuotation"
          @keydown="handleCheckoutKeydown"
          class="button primary expanded"
          ref="sendQuotationButton"
        >
          {{ $translate('SEND_REQUEST') }}
        </button>
      </div>
    </div>

    <!-- Quotation cart details, shown if the form is not enabled and the quotation is not sent -->
    <div v-else-if="!quotationSent" class="quotation-cart">
      <div v-if="quotationProducts.length" class="quotation-header">
        <p role="status" v-html="itemsInRequest"></p>
      </div>
      <div v-else class="quotation-empty">
        <div v-html="$translate('QUOTATION_CONTENT')"></div>
      </div>

      <div class="content">
        <!-- List of products in the quotation cart -->
        <QuotationCartItem
          v-for="product in quotationProducts"
          :key="product.shopping_cart_row_id"
          :product="product"
          @remove="removeProduct"
          @update-quantity="updateQuantity"
        />
      </div>

      <!-- Footer with total price and request quotation button -->
      <div class="footer" v-if="quotationProducts.length">
        <div class="total-price">
          <div class="total-price-label">
            {{ $translate('QUOTATION_TOTAL_SUM') }}
          </div>
          <div>
            {{ totalPrice }}
          </div>
        </div>

        <div class="customer-info">
          <button
            :aria-label="$translate('REQUEST_QUOTATION')"
            @click="showCustomerForm"
            @keydown="handleCheckoutKeydown"
            class="button primary hollow expanded"
            ref="sendQuotationButton"
          >
            {{ $translate('REQUEST_QUOTATION') }}
          </button>
        </div>
      </div>
    </div>

    <!-- Message shown when the quotation is sent -->
    <div class="quotation-sent" v-if="quotationSent && !quotationProducts.length">
      <div>
        <div v-html="$translate('QUOTATION_SENT')"></div>
        <div class="store-info margin-bottom-20">
          <span>
            {{ storeNumber }}
          </span>
          <span>
            {{ storeMail }}
          </span>
        </div>
      </div>

      <!-- Close button for the sent quotation message -->
      <button
        :aria-label="`${$translate('TEXT_CLOSE')} ${$translate('QUOTATION_SENT')}`"
        @click="closeCart"
        @keydown="handleCheckoutKeydown"
        class="button primary expanded"
        ref="sendQuotationButton"
      >
        {{ $translate('TEXT_CLOSE') }}
      </button>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, watch, inject, computed } from 'vue';
  import { useQuotationStore } from '@/stores/quotation-store.js';

  import CloseIcon from '@/components/icons/CloseIcon.vue';

  const { $translate } = inject('i18n');

  const props = defineProps({
    storeMail: String,
    storeNumber: String,
    customer: Object,
    storeName: String
  });

  const quotationStore = useQuotationStore();

  const quotationProducts = ref([]);
  const quotationSent = ref(false);
  const totalPrice = ref(0);
  const customerEmail = ref('');
  const customerCompany = ref('');
  const customerFirstName = ref('');
  const customerLastName = ref('');
  const customerMobile = ref('');
  const emailError = ref(false);
  const customerForm = ref(false);
  const customerComment = ref('');
  const personalDataApproved = ref(true);
  const closeCartButton = ref();
  const sendQuotationButton = ref();

  const itemsInRequest = computed(() => {
    const totalQuantity = quotationProducts.value.reduce((sum, product) => sum + product.quantity, 0);
    return $translate('ITEMS_IN_REQUEST').replace('%d', totalQuantity);
  });
  const approvePersonalDataText = computed(() => $translate('APPROVE_PERSONAL_DATA').replace('%s', props.storeName));

  // Initialize customer details if provided
  if (props.customer) {
    customerEmail.value = props.customer.email;
    customerMobile.value = props.customer.telephone;
    customerCompany.value = props.customer.company;
    customerFirstName.value = props.customer.first_name;
    customerLastName.value = props.customer.last_name;
  }

  // Validate email format
  const validateEmail = () => /^[\w-\.]+(\+[\w-]+)?@([\w-]+\.)+[\w-]{2,4}$/g.test(customerEmail.value);

  // Toggle customer form visibility
  const showCustomerForm = () => {
    customerForm.value = !customerForm.value;
  };

  // Update the draft quotation
  const updateDraft = async (email) => {
    const uuid = Cookie.getCookie('quotation_draft');
    const quotation = {
      comment: customerComment.value,
      products: quotationProducts.value.map(product => ({ id: product.shopping_cart_row_id, quantity: product.quantity })),
      email_address: email || 'user@example.com'
    };

    if (email || quotationProducts.value.length) {
      await quotationStore.editRequestForQuotationDraft(uuid, quotation);
    } else {
      await quotationStore.deleteRequestForQuotation(uuid);
      totalPrice.value = 0;
      Cookie.setCookie('quotation_draft', -1, '');
    }
  };

  // Fetch the draft quotation
  const fetchDraft = async () => {
    const uuid = Cookie.getCookie('quotation_draft');
    if (uuid) {
      const draft = await quotationStore.getRequestForQuotation(uuid);
      if (draft) {
        quotationProducts.value = draft.quotation_products;
      }
    }
  };

  // Remove a product from the quotation
  const removeProduct = async (productId) => {
    quotationProducts.value = quotationProducts.value.filter(product => product.shopping_cart_row_id !== productId);
    await updateDraft();
    document.querySelector('[data-close-quotation-cart]').focus();
  };

  // Update the quantity of a product in the quotation
  const updateQuantity = async (productId, newQuantity) => {
    if (newQuantity <= 0) {
      await removeProduct(productId);
    } else {
      quotationProducts.value = quotationProducts.value.map(product => ({
        ...product,
        quantity: product.shopping_cart_row_id === productId ? parseInt(newQuantity) : product.quantity
      }));
      await updateDraft();
    }
  };

  // Convert the draft to a qoutation request and send it
  const sendQuotation = async () => {
    if (!validateEmail()) {
      emailError.value = true;
      return;
    }
    await updateDraft(customerEmail.value);
    const uuid = Cookie.getCookie('quotation_draft');

    const quotation = {
      comment: customerComment.value,
      products: quotationProducts.value.map(product => ({ id: product.shopping_cart_row_id, quantity: product.quantity })),
      email_address: customerEmail.value,
      first_name: customerFirstName.value,
      last_name: customerLastName.value,
      company_name: customerCompany.value,
      phone_number: customerMobile.value
    };

    await quotationStore.sendDraftedRequestForQuotation(uuid, quotation);
    Cookie.setCookie('quotation_draft', -1, '');
    quotationProducts.value = [];
    quotationSent.value = true;
    totalPrice.value = 0;
    customerEmail.value = '';
    emailError.value = false;
    document.querySelector('[data-close-quotation-cart]').focus();
  };

  // Handle keyboard navigation
  
  const handleCheckoutKeydown = (event) => {

    const isTabPressed = event.key === 'Tab';
    const isShiftPressed = event.shiftKey;

    if (isTabPressed) {
      if (!isShiftPressed && event.target === sendQuotationButton.value) {
        event.preventDefault();
        closeCartButton.value.focus();
      } else if (isShiftPressed && event.target === closeCartButton.value) {
        event.preventDefault();
        sendQuotationButton.value.focus();
      }
    }

  };

  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      closeCart();
    }
  });

  // Close the quotation cart
  const closeCart = () => {
    document.querySelector('[data-exit-off-canvas]').click();
    Accessibility.hideVoiceOver('[data-slide-in-object="data-quotation-menu"]');
    quotationSent.value = false;
    customerForm.value = false;
    document.getElementById('skip-to-content-link').focus();
  };

  // Fetch the draft quotation on component mount
  onMounted(fetchDraft);

  // Watch for changes in the quotation store and update the component state accordingly
  watch(quotationStore.quotations, () => {
    const uuid = Cookie.getCookie('quotation_draft');
    if (!uuid) return;

    const rawQuotation = quotationStore.quotations[uuid];
    if (rawQuotation.quotation_products !== quotationProducts.value) {
      quotationProducts.value = rawQuotation.quotation_products;
    }

    totalPrice.value = rawQuotation.total_price;

    if (quotationSent.value) {
      totalPrice.value = 0;
    }
  });
</script>