<template>
  <div class="header-favorite-icon-wrapper">
    <a 
      :href="favoritesHref" 
      :title="favoritesPage" 
      :aria-label="favoritesPage"
      data-favorite-animation
      class="icon"
    >
      <HeartIcon
        :isSolid="customerProductListsStore.totalAmountOfFavorites > 0" 
        :iconPackage="heartIcon.packageNr"
        :fillColor="heartIcon.fillColor"
        :strokeColor="heartIcon.strokeColor" 
      />
      <span v-if="showLabel || showCounter" class="title">
        <span v-if="showLabel">{{ $translate('TEXT_MY_FAVORITES') }}</span>
        <span v-if="showCounter">({{ customerProductListsStore.totalAmountOfFavorites }})</span>
      </span>
    </a>
  </div>
</template>

<script setup>
import { resolveComponent, markRaw, computed } from 'vue';
import { useCustomerProductLists } from '@/stores/customer-products-list.js';
import HeartIcon from '@/components/icons/HeartIcon.vue';

const props = defineProps({
  favoritesHref: {
    required: true,
    type: String,
  },
  favoritesPage: {
    required: false,
    type: String,
  },
  showLabel: {
    required: false,
    type: Boolean
  },
  showCounter: {
    required: false,
    type: Boolean
  }
});

const customerProductListsStore = useCustomerProductLists();
const favoritesHref= props.favoritesHref;
const favoritesPage = props.favoritesPage;
const showLabel = props.showLabel;
const showCounter = props.showCounter;

const heartIcon = computed(() => {
  let packageNr = window.vendre_config.page_header.header_icons;
  let fillColor = window.vendre_config.general.favorite_icon_solid_color;
  let strokeColor = window.vendre_config.general.favorite_icon_outline_color;
  return { packageNr, fillColor, strokeColor }
});

</script>