<template>
  <button :class="buttonClasses" @click="saveToDraft">
    <QuotationIcon v-if="!isButton" />
    <span v-else>
      {{ $translate('ADD_TO_QUOTE') }}
    </span>
  </button>
</template>

<script setup>
  import { computed } from 'vue';
  import QuotationIcon from '@/assets/icons/list.svg?component';
  import { useQuotationStore } from '@/stores/quotation-store.js';

  const quotationStore = useQuotationStore();

  const props = defineProps({
    productId: {
      required: true,
      type: Number,
    },
    isButton: {
      required: false,
      type: Boolean,
      default: false,
    }
  });

  const isButton = computed(() => props.isButton);
  const buttonClasses = computed(() => isButton.value ? 'button primary hollow expanded' : '');

  const saveToDraft = async (e) => {
    e.preventDefault();

    const { productId } = props;
    const draftUuid = Cookie.getCookie('quotation_draft');
    const minifiedProducts = draftUuid ? await quotationStore.getMinifiedProducts(draftUuid) : [];
    const minifiedAttributes = quotationStore.getMinifiedAttributes();
    let existingProduct = {};

    if (Object.keys(minifiedAttributes).length > 0) {
      const stringifyAttributes = Object.entries(minifiedAttributes).map(([key, value]) => `{${key}}${value}`).join('');
      existingProduct = minifiedProducts.find(p => p.id.toString().includes(stringifyAttributes));
    } else {
      existingProduct = minifiedProducts.find(p => p.id === productId);
    }

    if (!existingProduct) {
      minifiedProducts.push({ id: productId, quantity: 1, attributes: minifiedAttributes });
    } else {
      existingProduct.quantity += 1;
    }

    const quotation = {
      comment: "Some user comment",
      products: minifiedProducts,
      email_address: "user@example.com"
    };

    draftUuid ? quotationStore.editRequestForQuotationDraft(draftUuid, quotation) : quotationStore.createRequestForQuotationDraft(quotation);
    document.querySelector('[data-slide-in-from-right="data-quotation-menu"]').click();
  };

</script>