<template>
  <div class="product-variant-buy-list row column">
    <div 
      v-for="(product, idx) in productVariants" 
      :key="product.id"
      class="product"
    >  
      <div class="left">
        <div>
          {{ product.model }}
        </div>

        <ProductStockStatus 
          v-if="showStockStatus"
          :allow-checkout="product.stock_allow_checkout"
          :stock="product.quantity"
        />
      </div>

      <div class="right">
        <div class="price-wrapper">
          <div v-if="!product.price_special">
            {{ product.price }}
          </div>

          <div
            v-if="product.price_special && product.price > product.price_special"
            class="old"
          >
            {{ product.price }}
          </div>

          <div 
            v-if="product.price_special"
            :class="{ new: product.price_special < product.price }"
          >
            {{ product.price_special }}
          </div>
        </div>

        <label :for="product.id" class="visually-hidden">
          {{ $translate('TABLE_HEADING_QUANTITY') }}
        </label>

        <BuyButton
          :product-id="Number(product.id)"
          :show-quantity-input="true"
          :lowest-number="product.lowest_number ? Number(product.lowest_number) : Number(product.package_size)"
          :package-size="Number(product.package_size)"
          :unit="product.unit"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue';
import { usePriceStore } from '@/stores/price-store.js';

const priceStore = usePriceStore();

const props = defineProps({
  variants: {
    required: true,
    type: Array
  },
  showStockStatus: {
    required: false,
    type: Boolean,
    default: false
  }
});

// Array for v-model
const products = reactive([]);
let productIds = [];
props.variants.forEach(product => {
  productIds.push(product.id);
  let mappedProduct = {
    id: product.id,
    quantity_diff: 0
  };

  products.push(mappedProduct);
});

const productVariants = ref(props.variants);

onMounted(async() => {
  await priceStore.getLoggedPrices(productIds);
  const productVariantsWithUpdatedPrices = productVariants.value.map((variant) => {
    if (priceStore.loggedPrices) {
      let product = priceStore.loggedPrices.find((item) => item.product_id == variant.id);
      if (product) {
        variant.price = product.price_log_price;
      }
    }
    return variant;
  });
  productVariants.value = productVariantsWithUpdatedPrices;
});
</script>