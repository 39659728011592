<template>
  <div class="products-lists-edit-wrapper">
    <div class="buttons">
      <!-- Edit name -->
      <button
        class="button primary small" 
        @click="editName=!editName" 
      >
        {{ $translate('CUSTOMER_LIST_CHANGE_NAME') }}
      </button>

      <!-- Remove -->
      <button
        @click="removeList"
        class="button secondary small" 
      >
        {{ $translate('IMAGE_BUTTON_DELETE') }}
      </button>
    </div>
    
    <!-- Form edit name-->
    <div v-show="editName" class="edit-name">
      <label for="listName">Namn</label>
      <input
        v-model="newListName"
        aria-errormessage="products-list-errors"
        name="listName"
        type="text"
      >
      <small
        v-html="newListNameError"
        :style="{ display: !!newListNameError ? '' : 'none' }"
        id="products-list-errors"
        class="err"
      />

      <div>
        <button 
          @click="saveListName"
          class="button primary small" 
        >
          {{ $translate('SAVE') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance  } from 'vue';
import axios from 'axios';

export default {
  props: {
    initList: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const API_URL = '/ajax.php?mode=customers_products_lists&hide_ajax_loader=true';
    const list = props.initList;
    const newListName = ref(list.name);
    const editName = ref(false);
    const newListNameError = ref('');
    const $translate = getCurrentInstance().appContext.config.globalProperties.$translate;

    async function saveListName() {
      if (!newListName.value || newListName.value.length == 0) {
        newListNameError.value = $translate('ACCOUNT_FAVORITE_LIST_ERROR_EMPTY');
        return;
      }
      if (newListName.value.length < 2) {
        newListNameError.value = $translate('ACCOUNT_FAVORITE_LIST_ERROR_LENGTH');
        return;
      }
      newListNameError.value = '';

      let formData = new FormData();
      formData.append('action', 'edit'); 
      formData.append('hash_id', list.hash_id);
      formData.append('name', newListName.value);
      let res = await axios(API_URL, {
        method: 'POST',
        data: formData
      });

      if (res.data?.status === true) {
        window.Loader.addLoader();
        window.location.reload();
      }
    }

    async function removeList() {
      const question = confirm($translate('ACCOUNT_FAVORITE_LIST_DELETE'));

      if (question == false) {
        return;
      }

      let formData = new FormData();
      formData.append('action', 'remove');
      formData.append('hash_id', list.hash_id);

      let res = await axios(API_URL, {
        method: 'POST',
        data: formData
      });

      if (res.data?.status === true) {
        window.Loader.addLoader();
        window.location.href = '/account.php?subpage=customers_products_lists';
      }
    }

    return {
      list,
      newListName,
      editName,
      saveListName,
      newListNameError,
      removeList
    }
  }
}
</script>

<style lang="scss" scoped>
.products-lists-edit-wrapper {
  margin-top: 20px;
  padding: 10px;
  .buttons {
    display: flex;
    gap: 7px;
  }
  .edit-name {
    width: 350px;
    margin: 20px 0;
  }

  .err {
    color: red;
    font-style: italic;
  }
}
</style>