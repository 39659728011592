<template>
  <div 
    class="product-stock-status-wrapper" 
    :class="stockClass"
  >
    {{ stockText }}
  </div>
</template>

<script setup>
import { computed, inject } from 'vue';
const { $translate } = inject('i18n');

const props = defineProps({
  allowCheckout: {
    required: true,
    type: [Boolean, String]
  },
  stock: {
    required: true,
    type: [Number, String]
  }
})

const stockStatus = computed(() => {
  if (props.stock == 0 && (props.allowCheckout == false || props.allowCheckout == '0')) {
    return 'out-of-stock';
  }

  if (props.stock == 0 && (props.allowCheckout == true || props.allowCheckout == '1')) {
    return 'stock-warning';
  }

  return 'in-stock';
});

const stockClass = computed(() => ({
  'out-of-stock': stockStatus.value == 'out-of-stock',
  'stock-warning': stockStatus.value == 'stock-warning'
}));

const stockText = computed(() => {
  if (stockStatus.value == 'out-of-stock') {
    return $translate('OUT_OF_STOCK'); 
  }

  if (stockStatus.value == 'stock-warning') {
    return $translate('NON_STOCK');
  }

  return $translate('IN_STOCK');
});
</script>