<template>
  <div class="filter-left-container">

    <div v-if="selectedOptions.length" class="selected-filters">
      <button
        v-for="(option, index) in selectedOptions"
        @click="uncheckFilter(option.name)"
        class="filtered-button" 
        :key="`${index}-${option.id}`"
      >
        <span>{{ formatName(option) }}</span>
        <CloseIcon />
      </button>
      
      <button 
        @click="resetFilters"
        class="clear-filters-button" 
      >
        <span>{{ $translate('BUTTON_CLEAR_FILTERS') }}</span>
      </button>
    </div>

    <div v-for="filter in filters" class="filter-wrapper" data-filter-menu :key="filter.id">
      
      <!-- Toggle button / Filter name -->
      <button
        v-if="filter.iscollapsible"
        @click="filter.iscollapsed = !filter.iscollapsed"
        type="button" 
        class="filter-name"
        :class="{ open: selectedFilters.includes(filter.id) }"
      >
        {{ filter.name }}
        <ChevronUpIcon v-if="!filter.iscollapsed"/>
        <ChevronDownIcon v-else/>
      </button>
      <div v-else class="filter-name">
        {{ filter.name }}
      </div>
      
      <span v-if="selectedOptionsInFilterCount(filter) > 0" class="number-of-filters-checked">
        {{ selectedOptionsInFilterCount(filter) }}
      </span>

      
      <!-- Menu -->
      <div 
        :class="{ open: !filter.iscollapsed || !filter.iscollapsible }"
        class="menu"
      > 
        <p v-if="filter.infoText" class="info-text">
          {{ filter.infoText }}
        </p>
        
        <input
          v-if="filter.isSearchable"
          v-model="filter.searchQuery"
          class="search-option"
          type="text"
          :placeholder="filter.searchPlaceholder"
        />
        <div 
          v-for="(option, index) in filteredOptions(filter)"
          v-show="showOption(filter, option)"
          :key="`${index}-${option.settings.name}`"
        >
          <!-- checkbox option -->
          <label v-if="option.settings.selectiontype && option.settings.selectiontype === 'checkbox' || option.settings.selectiontype === 'color' || !filtersTweakwise">
            <div>
              <div class="check">
                <input
                  type="checkbox"
                  :value="option"
                  :checked="existsInSelectedOptions(option)"
                  @change="updateSelectedOptions(option)"
                />
                <span class="checkmark" />
                <span class="name">{{ option.name }}</span>
                <span 
                  v-if="filter.isnrofresultsvisible"
                  class="nr-of-results"
                >({{ option.settings.nrofresults }})</span>
              </div>
            </div>
          </label>

          <!-- color option -->
          <!-- <label v-if="option.settings.selectiontype === 'color'">
            <button
              @click="updateSelectedOptions(option)"
              :class="{ selected: existsInSelectedOptions(option) }"
              class="color-palette-button"
            >
              <span
                :style="{backgroundColor: option.colorCode }"
                class="color-palette"></span>
              <span class="name">{{ option.name }}</span>
              <span 
                v-if="filter.isnrofresultsvisible"
                class="nr-of-results"
              >({{ option.settings.nrofresults }})</span>
            </button>
          </label> -->

          <!-- range slider option -->
          <RangeSlider
            v-if="option.settings.selectiontype == 'slider' && index < 1 && filtersTweakwise"
            @newRange="newRange"
            :minVal="filter.options[0].settings.value"
            :maxVal="filter.options[1].settings.value"
            :constMinVal="filter.options[2]?.settings.value"
            :constMaxVal="filter.options[3]?.settings.value"
            :option="option"
          />

          <RangeSlider
            v-if="filter.type == 2 && !filtersTweakwise"
            @newRange="newRange"
            :option="option"
            :minVal="filter.options[0].settings.value"
            :maxVal="filter.options[1].settings.value"
            :constMinVal="filter.options[2]?.settings.value"
            :constMaxVal="filter.options[3]?.settings.value"
          />
        </div>
        <button
          v-if="showMoreButton(filter)"
          @click="filter.showAllOptions = !filter.showAllOptions"
          class="show-all-options-button"
        >
          <span>{{ filter.showAllOptions ? filter.collapseDropdownText : filter.expandDropdownText }}</span>
        </button>
      </div>      
    </div>
  </div>  
</template>

<script setup>
import { useProductFilter } from '@/composables/product-listing-filter.js';

const props = defineProps({
  filtersTweakwise: {
    type: Object,
    required: false
  },
  filtersTwig: {
    type: Array,
    required: false,
  },
  productCount: {
    type: Number,
    required: true
  },
  closeMenuOnClickOutsideMenu: {
    type: Boolean,
    required: true
  },
  keywords: {
    type: String,
    required: false
  }
});

const {
  filters,
  filteredOptions,
  uncheckFilter,
  selectedFilters,
  selectedOptionsInFilterCount,
  selectedOptions,
  resetFilters,
  updateRangeSlider,
  updateSelectedOptions,
  existsInSelectedOptions,
  showOption,
  showMoreButton,
  formatName
} = useProductFilter(props);

const newRange = (option) => {
  updateRangeSlider(option);
};
</script>