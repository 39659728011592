<template>
  <div class="point">
    <button
      :style="[
        editMode ? { cursor: 'grab'} : { cursor: 'pointer' },
        { top: coordinateTop, left: coordinateLeft, background: pointInput.color }
      ]"
      @click="$emit('dotClick')"
      v-on="editMode ? { mousedown: dragMouseDown } : {}"
      class="dot"
      ref="dot"
      :aria-label="pointInput.title"
    />
    <div
      v-if="editMode && isActive"
      :style="{ top: coordinateTop, left: coordinateLeftPopop }"
      class="point-popup"
    >
      <form>
        <label for="title">{{ $translate('ADD_TITLE') }}</label>
        <input
          v-model="pointInput.title"
          name="title"
          :placeholder="$translate('ADD_TITLE')"
          type="text"
        >

        <label for="searchLinkField">{{ $translate('SEARCH_PRODUCT') }}</label>
        <ProductSearchInput
          @product-selected="updateProductData"
          name="searchLinkField"
          :placeholder="$translate('SEARCH_PRODUCT')"
        />

        <div v-if="pointInput.productData?.link" class="preview-product-link">
          <p>{{ $translate('CHOSEN_PRODUCT') }}</p>
          <a
            :href="pointInput.productData.link"
            :aria-label="pointInput.productData.name"
            target="blank"
          >
            {{ pointInput.productData.name }}
          </a>
        </div>

        <img
          v-if="pointInput.productData?.image"
          :src="pointInput.productData.image"
          class="preview-image"
        />

        <label for="description">{{ $translate('ADD_DESCRIPTION') }}</label>
        <input
          v-model="pointInput.description"
          name="description"
          :placeholder="$translate('ADD_DESCRIPTION')"
          type="text"
        >

        <label for="color">{{ $translate('PICK_COLOR') }}</label>
        <input v-model="pointInput.color" name="color" type="color">

        <button
          @click.prevent="submitPoint"
          class="button tiny"
        >
          {{ $translate('SAVE') }}
        </button>
        <button
          @click.prevent="$emit('deletePoint')"
          class="button tiny"
        >
          {{ $translate('IMAGE_BUTTON_DELETE') }}
        </button>
      </form>
    </div>

    <div
      :style="{ top: coordinateTop, left: coordinateLeftPopop }"
      v-if="!editMode && isActive"
      class="point-popup"
    >
      <p class="point-popup-title">{{ point.title }}</p>
      <p class="point-popup-description">{{ point.description }}</p>
      <a
        v-if="point.productData?.link"
        :href="point.productData.link"
        :aria-label="point.productData.name"
        class="button expanded point-popup-link"
      >
        {{ $translate('SHOP_THE_LOOK_PRODUCT_LINK') }}
      </a>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, reactive, onMounted } from 'vue'

const props = defineProps({
  point: {
    required: true,
    type: Object
  },
  isActive: {
    required: true,
    type: Boolean
  },
  editMode: {
    required: true,
    type: Boolean
  },
  container: {
    required: true
  }
});

const emit = defineEmits([
  'savePoint',
  'deletePoint',
  'dotClick',
  'updateCoordinates'
]);

onMounted(() => {
  checkVisibility();
});

const pointInput = ref(props.point);
const setAlignment = ref(null);
const newCoordinates = ref(null);
const dot = ref(null);

const dragPositions = reactive({
  clientX: null,
  clientY: null,
  movementX: 0,
  movementY: 0
});

const coordinateLeft = computed(() => {
  return props.point.coordinates[0] + '%';
});

const coordinateTop = computed(() => {
  return props.point.coordinates[1] + '%'        
});

const coordinateLeftPopop = computed(() => {
  let val = coordinateLeft.value;
  if (setAlignment.value == 'right') {
    val = 'calc(100% - 170px)';
  }
  if (setAlignment.value == 'left') {
    val = 'calc(0% + 150px)';
  }
  return val
});

const checkVisibility = () => {
  let diffRight = window.innerWidth - dot.value.getBoundingClientRect().right;
  let diffLeft = dot.value.getBoundingClientRect().left;
  if (diffRight <= 200) {
    setAlignment.value = 'right';
  }
  if (diffLeft <= 200) {
    setAlignment.value = 'left';
  }
}

const submitPoint = () => {
  emit('savePoint', pointInput)
}

const updateProductData = (productData) => {
  pointInput.value.productData = productData
}

const dragMouseDown = (event) => {
  event.preventDefault()
  dragPositions.clientX = event.clientX
  dragPositions.clientY = event.clientY
  document.onmousemove = elementDrag;
  document.onmouseup = closeDragElement;
}

const elementDrag = (event) => {
  dragPositions.movementX = dragPositions.clientX - event.clientX
  dragPositions.movementY = dragPositions.clientY - event.clientY
  dragPositions.clientX = event.clientX
  dragPositions.clientY = event.clientY

  // calculate new coordinates and convert to percentage 
  let height = props.container.getBoundingClientRect().height
  let width = props.container.getBoundingClientRect().width
  let x = (((dot.value.offsetLeft - dragPositions.movementX) / width) * 100)
  let y = (((dot.value.offsetTop - dragPositions.movementY) / height) * 100)

  newCoordinates.value =  [x, y]

  dot.value.style.left = x + '%';
  dot.value.style.top = y + '%';
}

const closeDragElement = () => {
  document.onmouseup = null
  document.onmousemove = null

  if (newCoordinates.value != null) {
    emit('updateCoordinates', props.point.id, newCoordinates);
  }
}
</script>