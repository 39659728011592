<template>
  <div class="range-slider-container">
    <div ref="sliderContainer" class="slider-container" @click="setSliderValue($event)" @touchstart="setSliderValue($event)">
      <div class="slider-track"></div>
      <div
        :style="{ left: `${minPercent}%` }"
        @mousedown="startDrag('min', $event)"
        @touchstart="startDrag('min', $event)"
        class="slider-thumb min-thumb"
      ></div>
      <div
        :style="{ left: `${maxPercent}%` }"
        @mousedown="startDrag('max', $event)"
        @touchstart="startDrag('max', $event)"
        class="slider-thumb max-thumb"
      ></div>
    </div>
    <div class="sum-container">
      <input
        type="number"
        @input="setSliderValueFromInput"
        v-model="minValue"
      >
      <input
        @blur="handleBlur"
        type="number"
        @input="setSliderValueFromInput"
        v-model="maxValue"
      >
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  minVal: String,
  maxVal: String,
  constMinVal: String,
  constMaxVal: String,
  option: Object,
});

const initialMinValue = 0;
const initialMaxValue = props.maxVal;

const sliderContainer = ref(null);
const dragging = ref(null);
const minValue = ref(props.minVal);
const maxValue = ref(props.maxVal);
const constMinVal = ref(props.constMinVal ? props.constMinVal : props.minVal);
const constMaxVal = ref(props.constMaxVal ? props.constMaxVal : props.maxVal);

const calculatePercent = (value) => {
  return ((parseInt(value) - constMinVal.value) / (constMaxVal.value - constMinVal.value)) * 100;
};

const handleBlur = () => {
  if (maxValue.value < minValue.value) {
    maxValue.value = minValue.value;
    updateRange();
  }
}

const setSliderValueFromInput = (event) => {

  if (maxValue.value < minValue.value) {
    return;
  }

  if (maxValue.value > initialMaxValue || maxValue.value < initialMinValue) {
    maxValue.value = initialMaxValue;
  }

  if (minValue.value < initialMinValue || minValue.value > initialMaxValue) {
    minValue.value = initialMinValue;
  }

  updateRange();
};

const minPercent = ref(calculatePercent(parseInt(minValue.value), constMinVal.value, constMaxVal.value));
const maxPercent = ref(calculatePercent(parseInt(maxValue.value), constMinVal.value, constMaxVal.value));

watch([minValue, maxValue], () => {
  minPercent.value = calculatePercent(minValue.value);
  maxPercent.value = calculatePercent(maxValue.value);
}, { deep: true });

const setSliderValue = (event) => {
  if (!sliderContainer.value || !dragging.value) return;
  
  const rect = sliderContainer.value.getBoundingClientRect();
  const x = (event.touches ? event.touches[0].clientX : event.clientX) - rect.left;
  const totalWidth = rect.width;
  const range = parseInt(constMaxVal.value) - parseInt(constMinVal.value);
  const minGap = range * 0.05;
  let newValue = ((x / totalWidth) * range) + parseInt(constMinVal.value);

  if (dragging.value === 'min') {
    newValue = Math.min(newValue, parseInt(maxValue.value) - minGap);
    newValue = parseFloat(newValue).toFixed(2);
    minValue.value = Math.max(newValue, parseInt(constMinVal.value)).toString();
  } else if (dragging.value === 'max') {
    newValue = Math.max(newValue, parseInt(minValue.value) + minGap);
    newValue = parseFloat(newValue).toFixed(2);
    maxValue.value = Math.min(newValue, parseInt(constMaxVal.value)).toString();
  }
};

const startDrag = (thumbType, event) => {
  event.preventDefault();
  dragging.value = thumbType;
  document.addEventListener('mousemove', setSliderValue);
  document.addEventListener('mouseup', stopDrag);
  document.addEventListener('touchmove', setSliderValue, { passive: true });
  document.addEventListener('touchend', stopDrag, { passive: true });
};

const emit = defineEmits(['newRange']);

const updateRange = () => {
  const paramValue = `${Math.round(minValue.value)}-${Math.round(maxValue.value)}`;
  const newRange = {
    name: paramValue,
    id: props.option.id,
    settings: {
      name: props.option.settings.name,
      value: paramValue,
      minVal: Math.round(minValue.value * 100) / 100,
      maxVal: Math.round(maxValue.value * 100) / 100
    }
  }

  emit('newRange', newRange);
};

const stopDrag = () => {
  document.removeEventListener('mousemove', setSliderValue);
  document.removeEventListener('mouseup', stopDrag);
  document.removeEventListener('touchmove', setSliderValue);
  document.removeEventListener('touchend', stopDrag);
  dragging.value = null;

  updateRange();
};

watch(() => props.minVal, (newVal) => {
  minValue.value = newVal;
});

watch(() => props.maxVal, (newVal) => {
  maxValue.value = newVal;
});

</script>
