import { defineStore } from "pinia";
import { ref } from 'vue';
import axios from "axios";

const baseUrl = '/surface/1/quotations';

export const useQuotationStore = defineStore('quotation', {
  state: () => ({
    quotations: ref({}),
  }),
  actions: {
    // Action to delete a quotation from the store
    deleteQuotationFromStore(quotation_uuid) {
      delete this.quotations[quotation_uuid];
    },
    // Action to save a quotation to the store
    saveQuotation(quotation) {
      this.quotations[quotation.uuid] = quotation;
    },
    // Send delete request and on success remove quotation from store
    removeRequestForQuotation(quotation_uuid) {
      axios.delete(`${baseUrl}/request-for-quotations?quotation_uuid=${quotation_uuid}`)
      .then(() => {
        this.deleteQuotationFromStore(quotation_uuid);
      })
      .catch(error => {
        console.error('Error removing quotation:', error);
      });
    },
    // Send quotation request and on success add to store
    createRequestForQuotation(quotation) {
      axios.post(`${baseUrl}/request-for-quotations`, quotation)
      .then(response => {
        this.deleteQuotationFromStore(quotation.uuid);
      })
      .catch(error => {
        console.error('Error creating quotation request:', error);
      });
    },
    // Send draft request and on success add to store
    createRequestForQuotationDraft(quotation) {
      axios.post(`${baseUrl}/request-for-quotations`, {...quotation, is_draft: true})
      .then(response => {
        this.saveQuotation(response.data);
        if (!Cookie.getCookie('quotation_draft')) {
          Cookie.setCookie('quotation_draft', 30, response.data.uuid);
        }
      })
      .catch(error => {
        console.error('Error creating quotation draft:', error);
      });
    },
    // Send edit draft request and on success update store
    editRequestForQuotationDraft(uuid, quotation) {
      return axios.put(`${baseUrl}/request-for-quotations?quotation_uuid=${uuid}`, {...quotation, is_draft: true})
      .then(response => {
        this.saveQuotation(response.data);
        return response.data;
      })
      .catch(error => {
        console.error('Error editing draft:', error);
      });
    },
    // Send request to get draft and on success add to store
    getRequestForQuotation(quotation_uuid) {
      return axios.get(`${baseUrl}/request-for-quotations?quotation_uuid=${quotation_uuid}`)
      .then(response => {
        this.saveQuotation(response.data);
        return response.data;
      })
      .catch(error => {
        if (error.response.status === 400) {
          Cookie.setCookie('quotation_draft', -1, '');
        }
      });
    },
    // Send request to delete draft and on success remove from store
    deleteRequestForQuotation(quotation_uuid) {
      axios.delete(`${baseUrl}/request-for-quotations?quotation_uuid=${quotation_uuid}`)
      .then(response => {
        this.deleteQuotationFromStore(quotation_uuid);
      })
      .catch(error => {
        console.error('Error deleting draft:', error);
      });
    },
    // Send and on success remove drafted quotation
    sendDraftedRequestForQuotation(uuid, quotation) {
      axios.put(`${baseUrl}/request-for-quotations?quotation_uuid=${uuid}`, quotation)
      .then(response => {
        this.deleteQuotationFromStore(uuid);
      })
      .catch(error => {
        console.error('Error sending drafted quotation:', error);
      });
    },
    // Get minified products
    async getMinifiedProducts(uuid) {
      const draft = await this.getRequestForQuotation(uuid);
      const minifiedAttributes = {};
      this.getMinifiedAttributes();
      return draft.quotation_products.map(product => ({
        id: product.shopping_cart_row_id,
        quantity: product.quantity,
        attributes: minifiedAttributes
      }));
    },

    // Get minified attributes
    getMinifiedAttributes: () => {
      const minifiedAttributes = {};

      let formElements = null;

      formElements = document.querySelector('form[name=add_product]');

      const formData = new FormData(formElements);
      let serializedFormData = new URLSearchParams(formData).toString();

      const params = new URLSearchParams(serializedFormData);
      for (const [key, value] of params.entries()) {
        const match = key.match(/^id\[(\d+)\]$/);
        if (match && value) {
          minifiedAttributes[match[1]] = value;
        }
      }
      return minifiedAttributes;
    }
  },
});