<template>
  <div class="product-quantity-input-wrapper">
    <p
      v-if="quantityNotification"
      class="quantity-notification"
      role="alert"
    >
      {{ $translate('TEXT_MIN_ORDER_QUANTITY') }}
    </p>
    <input 
      v-model="quantity"
      type="number"
      name="quantity"
      ref="quantityInput"
      :step="step"
      :class="{ 'hide-spin-buttons': props.hideSpinButtons }"
      :title="$translate('TABLE_HEADING_QUANTITY')"
      :tabindex="props.tabbable"
      :min="minQuantity"
      :id="`${Date.now()}-${labelId}`"
      @change="validateProductQuantityChange()"
      @focus="$emit('focus')" 
      @blur="$emit('blur')"
    />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  step: {
    type: Number,
    required: false,
    default: 1
  },
  lowestNumber: {
    type: Number,
    required: false,
    default: 1
  },
  packageSize: {
    type: Number,
    required: false,
  },
  hideSpinButtons: {
    type: Boolean,
    required: false,
    default: false
  },
  tabbable: {
    type: Number,
    required: false,
    default: 0
  },
  labelId: {
    required: false
  } 
});

const emit = defineEmits([
  'blur',
  'focus'
]);

const quantity = defineModel();
const quantityInput = ref();
const minQuantity = ref(props.lowestNumber);
const quantityNotification = ref(false);

const minQuantityCheck = () => {

  if (props.packageSize > 0 && (quantity.value % props.packageSize !== 0)) {
    minQuantity.value = Math.ceil(quantity.value / props.packageSize) * props.packageSize;
    quantity.value = Math.ceil(quantity.value / props.packageSize) * props.packageSize;
  } else {
    minQuantity.value = props.lowestNumber;
  }
};

watch(() => props.lowestNumber, (newValue, oldValue) => {
  minQuantityCheck();
}, { immediate: true });



const decreaseQuantity = () => {
  let num = props.packageSize > 1 ? props.packageSize : 1;

  if (quantity.value - num < props.lowestNumber || quantity.value - num < props.packageSize) {
    quantityNotification.value = true;
    return;
  }
  quantity.value = quantity.value - num;
}

const increaseQuantity = () => {
  let num = props.packageSize > 1 ? props.packageSize : 1;
  quantity.value = quantity.value + num;
}

defineExpose({
  increaseQuantity,
  decreaseQuantity,
  quantityInput
})

const validateProductQuantityChange = () => {      
  /** Here you can add validation to quantity changes.
   * For example if you want to add logic to handle purchase intervals
    */
  if (props.packageSize > 0) {
    if (quantity.value % props.packageSize !== 0) {
      quantity.value = Math.ceil(quantity.value / props.packageSize) * props.packageSize;
    }
  }

  if (quantity.value < minQuantity.value)  {
    quantity.value = minQuantity.value;
  }
}
</script>

<style lang="scss" scoped>
.hide-spin-buttons {
  /* Works for Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
}
</style>